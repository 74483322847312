'use strict';

module.exports = {
    // 年齢同意フラグをセッション保存
    setSessionAgeAgree: function () {
        $('#agree-yes,#agree-no').click(function (e) {
            e.preventDefault();
            let url = $(this).data('url');
            let agree = $(this).data('agree');
            let data = {
                agree: agree,
            };
            $.spinner().start();
            // 通信
            $.ajax({
                url: url,
                type: 'get',
                data: data,
                dataType: 'json',
                success: function (response) {
                    $.spinner().stop();
                    // ダイアログを閉じる
                    if (response.success) {
                        $('#alcoholic-beverages').remove();
                    }
                    // 同意しないを選んだ場合HOME画面へ遷移
                    if (response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                },

                error: function (err) {
                    $.spinner().stop();
                    // SFRA準拠
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                },
            });
        });
    },

    //　年齢確認画面の表示
    showConfirmAgeModal: function () {
        if ($('#consent-tracking') && $('#consent-tracking').length > 0) {
            return;
        }
        let hidVal = $('#age-agree').val();
        // 同意しない限り表示
        if (hidVal === 'null' || hidVal === '0') {
            let view = $('#alcoholic-beverages');
            view.modal('show');
        }
    },
};
