'use strict';

jQuery(() => {
    // 「商品追加なし」の「すぐ購入」ボタンがクリックされた際の処理（イベント委譲を使用）
    $(document).on('click', '.co-buy-now-noaddition', async (event) => {
        event.preventDefault();
        // 二重送信防止用の処理
        const clicklock = require('app_common/components/clicklock');
        if (!clicklock.lock()) {
            return;
        }
        try {
            // 「すぐ購入」を実行する
            const {getBuyNowObj} = require('./buyNow');
            const {execBuyNow} = require('../co/coBuyNow');
            const buyNowObj = getBuyNowObj(null, null);
            await execBuyNow(buyNowObj);
        } finally {
            clicklock.unlock();
        }
    });
});

const cart = require('base/cart/cart');

let updateMiniCart = true;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', async function (event, countPromise) {
        const count = await countPromise;
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems,
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        if ($('.search:visible').length === 0) {
            return;
        }
        const count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            const url = $('.minicart').data('action-url');

            let reqData = {}; // ミニカートを返すコントローラーに送信するリクエストデータ
            const $buyNowWrapper = $('#buyNowWrapper'); // 「すぐ購入」に関する処理を含むDIVタグ
            if ($buyNowWrapper.length > 0) {
                // --- DOM上にすでに「すぐ購入」に関する処理が存在する場合
                reqData.isNoBuyNowHandler = true; // ミニカートに「すぐ購入」に関する処理を含めないよう要求する
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, reqData, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if (
            (event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')
        ) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
