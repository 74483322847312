'use strict';

/** @type {object} 確認ダイアログ */
const CONFIRMATION = {
    /** @type {string} デフォルトのモーダルID */
    DEFAULT_ID: 'confirmationModal',
    /** @type {object} 確認ダイアログ内のボタン */
    BUTTON: {
        /** @type {string} ボタン「OK」のクラス名 */
        OK: 'co-modal-ok',
        /** @type {string} ボタン「CANCEL」のクラス名 */
        CANCEL: 'co-modal-cancel',
    },
    /** @type {object} 確認ダイアログ結果 */
    RESULT: {
        /** @type {number} OK */
        OK: 0,
        /** @type {number} CANCEL */
        CANCEL: -1,
    },
};

/**
 * 確認ダイアログ(モーダル)を表示し、押されたボタンが何であるかを返す。
 * なお、ページ内に確認ダイアログのHTMLが含まれていること。
 *
 * (参考)/co/coUses/coUsesModal.isml
 * (確認ダイアログのHTMLの前提)
 * - OKボタンのclassが「co-modal-ok」である
 * - CANCELボタンのclassが「co-modal-cancel」である
 *
 * 本関数は、条件に応じて確認ダイアログを表示したい場合に利用することを想定。
 * そのため、特定のボタン要素にモーダル用のデータ属性を用いて表示をトグルする用途では利用しないこと。
 * (例)「data-target="#confirmationModal" data-toggle="modal"」
 *
 * @param {string|undefined} modalId - 確認ダイアログ(モーダル)の要素ID。指定しない場合(undefined時)のデフォルトは「confirmationModal」。
 * @returns {Promise<number>} CONFIRMATION.RESULT.OK または CONFIRMATION.RESULT.CANCEL
 */
function confirm(modalId) {
    return new Promise((resolve, reject) => {
        /* eslint no-unused-vars: ["error", { "args": "none" }] */
        // モーダルを取得する
        const $modal = $(`#${modalId || CONFIRMATION.DEFAULT_ID}`);
        // モーダルがクローズした場合の処理
        $modal.on('hide.bs.modal', () => {
            // XXX これは、ボタンが押されずにモーダルがクローズした場合の処理。ボタンが押された場合はすでにresolve済みとなる。
            resolve(CONFIRMATION.RESULT.CANCEL);
        });
        // OKボタンが押された場合の処理
        $(`.${CONFIRMATION.BUTTON.OK}`, $modal).on('click', () => {
            resolve(CONFIRMATION.RESULT.OK);
        });
        // CANCELボタンが押された場合の処理
        $(`.${CONFIRMATION.BUTTON.CANCEL}`, $modal).on('click', () => {
            resolve(CONFIRMATION.RESULT.CANCEL);
        });
        // モーダルを表示する
        $modal.modal('show');
    });
}

module.exports = {
    CONFIRMATION,
    confirm,
};
