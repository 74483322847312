'use strict';

/**
 * HTML特殊文字をエンコードして返す。
 *
 * @param {string} str - エンコードしたい文字列。 例: &lt;span class="foo"&gt;hoge&lt;/span&gt;
 * @returns {string} エンコード済みの文字列。 例: &amp;lt;span class=&amp;quot;foo&amp;quot;&amp;gt;hoge&amp;lt;/span&amp;gt;
 */
function encodeHtml(str) {
    if (!str) {
        return str;
    }
    const encoded = str.replace(/[<>&"'`]/g, (match) => {
        const encodeMap = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#x27;',
            '`': '&#x60;',
        };
        return encodeMap[match];
    });
    return encoded;
}

/**
 * 「見た目がハイフンに見える文字列」を半角ハイフン/マイナス(\u002d)に変換して返す。
 * 引数に見た目がハイフンに見える文字列が含まれない場合は、引数と同じ文字列を返す。
 *
 * ここで「見た目がハイフンに見える文字列」は以下とする。
 * - \uff0d ... 全角ハイフン/マイナス
 * - \u30fc ... 延長サウンドマーク(全角カタカナ/全角ひらがな)
 * - \u2010 ... ハイフン
 * - \u2011 ... 改行のないハイフン
 * - \u2013 ... アンダッシュ
 * - \u2014 ... 全角ダッシュ
 * - \u2015 ... 水平バー/引用符ダッシュ
 * - \u2212 ... マイナス記号
 * - \uff70 ... 延長サウンドマーク(半角カタカナ)
 *
 * @param {string} str - 変換対象の文字列
 * @returns {string} 変換後の文字列
 */
function convertToHyphen(str) {
    if (!str) {
        return str;
    }
    const converted = str.replace(/[\uff0d\u30fc\u2010\u2011\u2013\u2014\u2015\u2212\uff70]/g, '-');
    return converted;
}

/**
 * 全角数字を半角数字に変換して返す。
 * 引数に全角数字が含まれない場合は、引数と同じ文字列を返す。
 *
 * @param {string} str - 変換対象の文字列
 * @returns {string} 変換後の文字列
 */
function convertToHalfWidthDigit(str) {
    if (!str) {
        return str;
    }
    const converted = str.replace(/[\uff10-\uff19]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    return converted;
}

/**
 * 「見た目が全角の延長サウンドマークに見える文字列」を全角の延長サウンドマーク(\u30fc)に変換して返す。
 * 引数に見た目が全角の延長サウンドマークに見える文字列が含まれない場合は、引数と同じ文字列を返す。
 * なお、「PSM」は「Prolonged Sound Mark」の略記である。
 *
 * ここで「見た目が全角の延長サウンドマークに見える文字列」は以下とする。
 * - \u2010 ... 「‐」 (ハイフン)
 * - \u2011 ... 「‑」 (改行のないハイフン)
 * - \u2013 ... 「–」 (アンダッシュ/エンダッシュ)
 * - \u2014 ... 「—」 (全角ダッシュ/エムダッシュ)
 * - \u2015 ... 「―」 (水平バー/引用符ダッシュ)
 * - \u2212 ... 「−」 (マイナス記号)
 * - \uff0d ... 「－」 (全角ハイフン/全角マイナス)
 * - \u002d ... 「-」 (半角ハイフン/ハイフンマイナス)
 * - \uff70 ... 「ｰ」 (延長サウンドマーク(半角カタカナ))
 *
 * @param {string} str - 変換対象の文字列
 * @returns {string} 変換後の文字列
 */
function convertToPSM(str) {
    if (!str) {
        return str;
    }
    const converted = str.replace(
        /[\u2010\u2011\u2013\u2014\u2015\u2212\uff0d\u002d\uff70]/g,
        'ー'
    );
    return converted;
}

/**
 * 半角文字列を全角文字列に変換して返す。
 *
 * なお、変換する半角文字列は次の通りである。キーボードで入力される一般的な半角文字列を想定している。
 * これら以外の半角文字列は変換されない。
 *
 * | 変換前                             | 変換後                                  | 備考                                              |
 * |:-----------------------------------|:----------------------------------------|:--------------------------------------------------|
 * | 半角カナ                           | 全角カナ                                | 「katakanaMap」に示すマッピングに基づく           |
 * | `\u0021\u0023-\u0026\u0028-\u007E` | Unicodeのアドレスを0xFEE0だけ移動した値 | -                                                 |
 * | `\u0022`                           | `\u201D`                                | `\u0022`: `"`, `\u201D`: `”`                     |
 * | `\u0027`                           | `\u2019`                                | `\u0027`: `'`, `\u2019`: `’`                     |
 * | `\u0020`                           | `\u3000`                                | `\u0020`: ` `(半角空白), `\u3000`: `　`(全角空白) |
 *
 * @param {string} str - 変換対象の文字列
 * @returns {string} 変換後の文字列
 */
function convertToFullWidth(str) {
    if (!str) {
        return str;
    }
    const katakanaMap = {
        ｶﾞ: 'ガ',
        ｷﾞ: 'ギ',
        ｸﾞ: 'グ',
        ｹﾞ: 'ゲ',
        ｺﾞ: 'ゴ',
        ｻﾞ: 'ザ',
        ｼﾞ: 'ジ',
        ｽﾞ: 'ズ',
        ｾﾞ: 'ゼ',
        ｿﾞ: 'ゾ',
        ﾀﾞ: 'ダ',
        ﾁﾞ: 'ヂ',
        ﾂﾞ: 'ヅ',
        ﾃﾞ: 'デ',
        ﾄﾞ: 'ド',
        ﾊﾞ: 'バ',
        ﾋﾞ: 'ビ',
        ﾌﾞ: 'ブ',
        ﾍﾞ: 'ベ',
        ﾎﾞ: 'ボ',
        ﾊﾟ: 'パ',
        ﾋﾟ: 'ピ',
        ﾌﾟ: 'プ',
        ﾍﾟ: 'ペ',
        ﾎﾟ: 'ポ',
        ｳﾞ: 'ヴ',
        ﾜﾞ: 'ヷ',
        ｦﾞ: 'ヺ',
        ｱ: 'ア',
        ｲ: 'イ',
        ｳ: 'ウ',
        ｴ: 'エ',
        ｵ: 'オ',
        ｶ: 'カ',
        ｷ: 'キ',
        ｸ: 'ク',
        ｹ: 'ケ',
        ｺ: 'コ',
        ｻ: 'サ',
        ｼ: 'シ',
        ｽ: 'ス',
        ｾ: 'セ',
        ｿ: 'ソ',
        ﾀ: 'タ',
        ﾁ: 'チ',
        ﾂ: 'ツ',
        ﾃ: 'テ',
        ﾄ: 'ト',
        ﾅ: 'ナ',
        ﾆ: 'ニ',
        ﾇ: 'ヌ',
        ﾈ: 'ネ',
        ﾉ: 'ノ',
        ﾊ: 'ハ',
        ﾋ: 'ヒ',
        ﾌ: 'フ',
        ﾍ: 'ヘ',
        ﾎ: 'ホ',
        ﾏ: 'マ',
        ﾐ: 'ミ',
        ﾑ: 'ム',
        ﾒ: 'メ',
        ﾓ: 'モ',
        ﾔ: 'ヤ',
        ﾕ: 'ユ',
        ﾖ: 'ヨ',
        ﾗ: 'ラ',
        ﾘ: 'リ',
        ﾙ: 'ル',
        ﾚ: 'レ',
        ﾛ: 'ロ',
        ﾜ: 'ワ',
        ｦ: 'ヲ',
        ﾝ: 'ン',
        ｧ: 'ァ',
        ｨ: 'ィ',
        ｩ: 'ゥ',
        ｪ: 'ェ',
        ｫ: 'ォ',
        ｯ: 'ッ',
        ｬ: 'ャ',
        ｭ: 'ュ',
        ｮ: 'ョ',
        '｡': '。',
        '､': '、',
        ｰ: 'ー',
        '｢': '「',
        '｣': '」',
        '･': '・',
        ﾞ: '゛',
        ﾟ: '゜',
    };
    const katakanaRegExp = new RegExp(`(?:${Object.keys(katakanaMap).join('|')})`, 'g');
    const fullWidth = str
        .normalize()
        .replace(katakanaRegExp, function (char) {
            return katakanaMap[char] || char;
        })
        .replace(/[\u0021\u0023-\u0026\u0028-\u007E]/g, function (char) {
            return String.fromCharCode(char.charCodeAt(0) + 0xfee0);
        })
        .replace(/\u0022/g, '\u201D')
        .replace(/\u0027/g, '\u2019')
        .replace(/\u0020/g, '\u3000');
    return fullWidth;
}

/**
 * 全角カナ文字列を半角カナ文字列に変換して返す。
 * 全角カナ以外の文字列は変換されない。
 *
 * | 変換前   | 変換後   | 備考                                    |
 * |:---------|:---------|:----------------------------------------|
 * | 全角カナ | 半角カナ | 「katakanaMap」に示すマッピングに基づく |
 *
 * @param {string} str - 変換対象の文字列
 * @returns {string} 変換後の文字列
 */
function convertFullWidthKatakanaToHalfWidthKatakana(str) {
    if (!str) {
        return str;
    }
    const katakanaMap = {
        ガ: 'ｶﾞ',
        ギ: 'ｷﾞ',
        グ: 'ｸﾞ',
        ゲ: 'ｹﾞ',
        ゴ: 'ｺﾞ',
        ザ: 'ｻﾞ',
        ジ: 'ｼﾞ',
        ズ: 'ｽﾞ',
        ゼ: 'ｾﾞ',
        ゾ: 'ｿﾞ',
        ダ: 'ﾀﾞ',
        ヂ: 'ﾁﾞ',
        ヅ: 'ﾂﾞ',
        デ: 'ﾃﾞ',
        ド: 'ﾄﾞ',
        バ: 'ﾊﾞ',
        ビ: 'ﾋﾞ',
        ブ: 'ﾌﾞ',
        ベ: 'ﾍﾞ',
        ボ: 'ﾎﾞ',
        パ: 'ﾊﾟ',
        ピ: 'ﾋﾟ',
        プ: 'ﾌﾟ',
        ペ: 'ﾍﾟ',
        ポ: 'ﾎﾟ',
        ヴ: 'ｳﾞ',
        ヷ: 'ﾜﾞ',
        ヺ: 'ｦﾞ',
        ア: 'ｱ',
        イ: 'ｲ',
        ウ: 'ｳ',
        エ: 'ｴ',
        オ: 'ｵ',
        カ: 'ｶ',
        キ: 'ｷ',
        ク: 'ｸ',
        ケ: 'ｹ',
        コ: 'ｺ',
        サ: 'ｻ',
        シ: 'ｼ',
        ス: 'ｽ',
        セ: 'ｾ',
        ソ: 'ｿ',
        タ: 'ﾀ',
        チ: 'ﾁ',
        ツ: 'ﾂ',
        テ: 'ﾃ',
        ト: 'ﾄ',
        ナ: 'ﾅ',
        ニ: 'ﾆ',
        ヌ: 'ﾇ',
        ネ: 'ﾈ',
        ノ: 'ﾉ',
        ハ: 'ﾊ',
        ヒ: 'ﾋ',
        フ: 'ﾌ',
        ヘ: 'ﾍ',
        ホ: 'ﾎ',
        マ: 'ﾏ',
        ミ: 'ﾐ',
        ム: 'ﾑ',
        メ: 'ﾒ',
        モ: 'ﾓ',
        ヤ: 'ﾔ',
        ユ: 'ﾕ',
        ヨ: 'ﾖ',
        ラ: 'ﾗ',
        リ: 'ﾘ',
        ル: 'ﾙ',
        レ: 'ﾚ',
        ロ: 'ﾛ',
        ワ: 'ﾜ',
        ヲ: 'ｦ',
        ン: 'ﾝ',
        ァ: 'ｧ',
        ィ: 'ｨ',
        ゥ: 'ｩ',
        ェ: 'ｪ',
        ォ: 'ｫ',
        ッ: 'ｯ',
        ャ: 'ｬ',
        ュ: 'ｭ',
        ョ: 'ｮ',
        '。': '｡',
        '、': '､',
        ー: 'ｰ',
        '「': '｢',
        '」': '｣',
        '・': '･',
        '゛': 'ﾞ',
        '゜': 'ﾟ',
    };
    const katakanaRegExp = new RegExp(`(?:${Object.keys(katakanaMap).join('|')})`, 'g');
    const halfWidth = str.normalize().replace(katakanaRegExp, function (char) {
        return katakanaMap[char] || char;
    });
    return halfWidth;
}

module.exports = {
    encodeHtml,
    convertToHyphen,
    convertToHalfWidthDigit,
    convertToPSM,
    convertToFullWidth,
    convertFullWidthKatakanaToHalfWidthKatakana,
};
