'use strict';

/**
 * 「すぐ購入」に関するオブジェクトを作成して返す。
 * このオブジェクトは、co/coBuyNow#execBuyNowのパラメーターとして利用する。
 *
 * @param {(string|null)} productId - カートに追加する商品のID。追加しない場合はnullとする。 例: `'12567'`
 * @param {(number|null)} quantity - カートに追加する商品の数量。追加しない場合はnullとする。 例: `2`
 * @returns {object} buyNowObj - 「すぐ購入」のAjaxサービスに送信するデータを含むオブジェクト。フォーム定義「coBuyNow.xml」に対応する。
 * - `originUrl`: <「すぐ購入」アクション発生元の画面のURL。「/」始まりとする。>
 * - `productId`: <カートに追加する商品のID>
 * - `quantity`: <カートに追加する商品の数量>
 * - `forceChangeUses`: <用途を強制的に変更するか否か>
 * ```js
 * {
 *     originUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Product-Show?pid=12567',
 *     productId: '12567',
 *     quantity: 2,
 *     forceChangeUses: false,
 * }
 * ```
 */
function getBuyNowObj(productId, quantity) {
    // 「すぐ購入」アクション発生元の画面のURL
    const originUrl = `${window.location.pathname}${window.location.search}`;
    const buyNowObj = {
        originUrl,
        productId,
        quantity,
        forceChangeUses: false, // 「用途を強制的に変更する」オプションは「false」とする
    };
    return buyNowObj;
}

module.exports = {
    getBuyNowObj,
};
