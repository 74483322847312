'use strict';

/**
 * Ajaxでフォームデータ(FormData)をPOST送信し、そのレスポンスデータを返す。
 *
 * 通信時にエラーが発生し、かつリダイレクト情報(redirectUrl)が存在する場合はErrorをスローせず、正常時と同様にレスポンスデータを返す。
 * このとき、redirectUrlにはエラー画面のURLがセットされていることを想定している。
 * 利用側はredirectUrlを適切にハンドリングする。
 * ```js
 * window.location.href = data.redirectUrl;
 * ```
 *
 * 一方で、通信時にエラーが発生し、かつリダイレクト情報(redirectUrl)が存在しない場合はErrorをスローする。
 * 利用側はErrorを適切にハンドリング(キャッチ)する。
 *
 * @param {string} url - フォームデータ(FormData)送信先のURL
 * @param {object} formData - フォームデータ(FormData)
 * @param {boolean} [isMultiPart=false] - `enctype`を`'multipart/form-data'`とするか否か。
 * - `true`の場合: 引数のFormDataをそのまま送信する。結果、`enctype`は`'multipart/form-data'`となる。
 * - `false`の場合: 引数のFormDataをURLSearchParamsに変換して送信する。結果、`enctype`は`'application/x-www-form-urlencoded'`となる。
 * @returns {object} レスポンスのJSONに相当するJavaScriptオブジェクト
 * @throws {Error} 通信時にエラーが発生した、かつリダイレクト情報が存在しない場合
 */
module.exports = async (url, formData, isMultiPart) => {
    let body;
    if (isMultiPart === true) {
        body = formData;
    } else {
        const urlSearchParams = new URLSearchParams();
        // eslint-disable-next-line no-restricted-syntax
        for (const entry of formData.entries()) {
            const key = entry[0];
            const value = entry[1];
            urlSearchParams.append(key, value);
        }
        body = urlSearchParams;
    }
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Cache-Control': 'no-cache',
            'X-Requested-With': 'XMLHttpRequest',
        },
        body,
        credentials: 'same-origin',
    });
    const data = await response.json();
    if (!response.ok) {
        if (data.redirectUrl) {
            return data;
        }
        // ステータスコード(500など)を含めてエラーをスローする
        throw new Error(response.status);
    }
    return data;
};
