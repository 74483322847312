'use strict';

/*
 * 「すぐ購入」用のクライアントJS。
 * 「co/coBuyNow/coBuyNowParts.isml」をインクルードした画面上で機能する。
 */

const {CONFIRMATION, confirm} = require('./client/coClientModalUtils');
const postForm = require('sswd_base/components/XPostForm');
const {getCsrfObject} = require('app_common/components/security');

/** @type {object} 「すぐ購入」サービスのレスポンス結果 */
const CO_BUY_NOW_RESPONSE_RESULT = {
    /** @type {string} 未ログインの場合 */
    NOT_LOGGED_IN: 'notLoggedIn',
    /** @type {string} 「すぐ購入」が可能である場合 */
    OK: 'ok',
    /** @type {string} エラーが発生した場合 */
    ERROR: 'error',
    /** @type {string} 商品が購入不可である場合 */
    CAN_NOT_BUY: 'canNotBuy',
    /** @type {string} 用途を「ご自宅用」に変更する必要がある場合 */
    CHANGE_USES: 'changeUses',
};

/** @type {object} 「すぐ購入」サービスのレスポンスを処理した結果 */
const CO_BUY_NOW_ACTION_RESULT = {
    /** @type {string} CSRFトークンチェックでエラーが発生した場合 */
    CSRF_FAIL: 'csrfFail',
    /** @type {string} 未ログインの場合 */
    NOT_LOGGED_IN: 'notLoggedIn',
    /** @type {string} 「すぐ購入」が可能である場合 */
    OK: 'ok',
    /** @type {string} エラーが発生した場合 */
    ERROR: 'error',
    /** @type {string} 商品が購入不可である場合 */
    CAN_NOT_BUY: 'canNotBuy',
    /** @type {string} 用途を「ご自宅用」に変更することが承認された場合 */
    CHANGE_USES_OK: 'changeUsesOk',
    /** @type {string} 用途を「ご自宅用」に変更することが承認されなかった場合 */
    CHANGE_USES_NG: 'changeUsesNg',
    /** @type {string} 不明な状態 */
    UNKNOWN: 'unknown',
};

/** @type {object} 「すぐ購入」用のモーダルダイアログ */
const CO_BUY_NOW_MODAL = {
    /** @type {string} エラーが発生した場合のダイアログのID */
    ERROR: 'coBuyNowErrorModal',
    /** @type {string} 商品が購入不可である場合のダイアログのID */
    CAN_NOT_BUY: 'coBuyNowCanNotBuyModal',
    /** @type {string} 用途を変更する場合のダイアログのID */
    CHANGE_USES: 'coBuyNowChangeUsesModal',
};

/**
 * モーダルの位置を適切な位置に移動する。
 * モーダルが特定の要素内に配置されている場合に、ボタンを押せないケースが生じるのを防ぐことが目的。
 *
 * @param {string} modalId - モーダルのID 例: `'coBuyNowErrorModal'`
 * @returns {void}
 */
function locateModal(modalId) {
    const $modal = $(`#${modalId}`);
    if ($modal.length === 0) {
        return;
    }
    const $body = $(document.body);
    const bodyTagName = $body.prop('tagName');
    const $parent = $modal.parent();
    const parentTagName = $parent.prop('tagName');
    if (bodyTagName === parentTagName) {
        // --- タグ名が同じ場合(どちらもBODYの場合)
        // すでにbody直下にモーダルが存在するとみなし、何もしない
        return;
    }
    // --- タグ名が異なる場合
    // body直下にモーダルを移動する
    $modal.detach().appendTo($body);
}

/**
 * POST送信を実行し、その結果を含むオブジェクトを返す。
 *
 * @param {string} actionUrl 「すぐ購入」サービスのURL
 * @param {FormData} formData 「すぐ購入」サービスに送信するフォームデータ
 * @param {boolean} フォームデータ内にCSRFトークンが存在するか否か
 * @returns {object} POST送信を実行した結果を表すオブジェクト
 * (例) CSRFトークンチェックでエラーが発生した場合(「CSRF-AjaxFail」が返すレスポンスに該当)
 * ```js
 * {
 *     response: {
 *         csrfError: true,
 *         redirectUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/CSRF-Fail', // CSRFエラー画面のURLがセットされる
 *     },
 *     actionResult: 'csrfFail', // 「CO_BUY_NOW_ACTION_RESULT.CSRF_FAIL」に該当
 * }
 * ```
 * (例) 未ログインの場合(CO_BUY_NOW_RESPONSE_RESULT.NOT_LOGGED_IN)
 * ```js
 * {
 *     response: {
 *         result: 'notLoggedIn', // 「CO_BUY_NOW_RESPONSE_RESULT.NOT_LOGGED_ID」に該当
 *         redirectUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Login-Show?rurl=102', // ログイン画面のURLがセットされる
 *         originUrl: null, // nullとなる
 *     },
 *     actionResult: 'notLoggedIn', // 「CO_BUY_NOW_ACTION_RESULT.NOT_LOGGED_ID」に該当
 * }
 * ```
 * (例) 「すぐ購入」が可能である場合(CO_BUY_NOW_RESPONSE_RESULT.OK)
 * ```js
 * {
 *     response: {
 *         result: 'ok', // 「CO_BUY_NOW_RESPONSE_RESULT.OK」に該当
 *         redirectUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Co-Start?stage=confirmation', // チェックアウト画面のURLがセットされる
 *         originUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Product-Show?pid=12567', // 元画面のURLがセットされる
 *     },
 *     actionResult: 'ok', // 「CO_BUY_NOW_ACTION_RESULT.OK」に該当
 * }
 * ```
 * (例) エラーが発生した場合 (CO_BUY_NOW_RESPONSE_RESULT.ERROR)
 * ```js
 * {
 *     response: {
 *         result: 'error', // 「CO_BUY_NOW_RESPONSE_RESULT.ERROR」に該当
 *         redirectUrl: null, // nullとなる
 *         originUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Product-Show?pid=12567', // 元画面のURLがセットされる
 *         message: 'エラーが発生しました。', // エラーメッセージがセットされる
 *     },
 *     actionResult: 'error', // 「CO_BUY_NOW_ACTION_RESULT.ERROR」に該当
 * }
 * ```
 * (例) 商品が購入不可である場合 (CO_BUY_NOW_RESPONSE_RESULT.CAN_NOT_BUY)
 * ```js
 * {
 *     response: {
 *         result: 'canNotBuy', // 「CO_BUY_NOW_RESPONSE_RESULT.CAN_NOT_BUY」に該当
 *         redirectUrl: null, // nullとなる
 *         originUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Product-Show?pid=12567', // 「originUrl」を元に作成したURLがセットされる
 *     },
 *     actionResult: 'canNotBuy', // 「CO_BUY_NOW_ACTION_RESULT.CAN_NOT_BUY」に該当
 * }
 * ```
 * (例) 用途を「ご自宅用」に変更する必要がある場合 (CO_BUY_NOW_RESPONSE_RESULT.CHANGE_USES)
 * ```js
 * {
 *     response: {
 *         result: 'changeUses', // 「CO_BUY_NOW_RESPONSE_RESULT.CHANGE_USES」に該当
 *         redirectUrl: null, // nullとなる
 *         originUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Product-Show?pid=12567', // 「originUrl」を元に作成したURLがセットされる
 *     },
 *     actionResult: 'changeUsesOk', // 「CO_BUY_NOW_ACTION_RESULT.CHANGE_USES_OK」に該当。「CO_BUY_NOW_ACTION_RESULT.CHANGE_USES_NG」の場合は「changeUsesNg」となる。
 * }
 * ```
 */
async function doCoBuyNowServiceAction(actionUrl, formData) {
    let response;
    let actionResult;
    try {
        $.spinner().start();
        // 「すぐ購入」サービスを呼び出す
        response = await postForm(actionUrl, formData);
        if (response.csrfError) {
            // --- 「CSRF-AjaxFail」によってCSRFエラーが返された場合
            actionResult = CO_BUY_NOW_ACTION_RESULT.CSRF_FAIL;
        } else {
            const responseResult = response.result;
            if (responseResult === CO_BUY_NOW_RESPONSE_RESULT.NOT_LOGGED_IN) {
                // --- 「notLoggedIn」の場合
                actionResult = CO_BUY_NOW_ACTION_RESULT.NOT_LOGGED_IN;
            } else if (responseResult === CO_BUY_NOW_RESPONSE_RESULT.OK) {
                // --- 「ok」の場合
                actionResult = CO_BUY_NOW_ACTION_RESULT.OK;
            } else if (responseResult === CO_BUY_NOW_RESPONSE_RESULT.ERROR) {
                // --- 「error」の場合
                const modalId = CO_BUY_NOW_MODAL.ERROR;
                locateModal(modalId);
                // エラーメッセージをセットする
                const message = response.message;
                const $modal = $(`#${modalId}`);
                const coBuyNowErrorMessageId = 'coBuyNowErrorMessage';
                const $coBuyNowErrorMessage = $(`#${coBuyNowErrorMessageId}`, $modal);
                const XStringUtils = require('sswd_base/util').XStringUtils;
                $coBuyNowErrorMessage.text(`${XStringUtils.encodeHtml(message)}`);
                // ダイアログを表示する
                await confirm(modalId);
                actionResult = CO_BUY_NOW_ACTION_RESULT.ERROR;
            } else if (responseResult === CO_BUY_NOW_RESPONSE_RESULT.CAN_NOT_BUY) {
                // --- 「canNotBuy」の場合
                // ダイアログを表示する
                const modalId = CO_BUY_NOW_MODAL.CAN_NOT_BUY;
                locateModal(modalId);
                await confirm(modalId);
                actionResult = CO_BUY_NOW_ACTION_RESULT.CAN_NOT_BUY;
            } else if (responseResult === CO_BUY_NOW_RESPONSE_RESULT.CHANGE_USES) {
                // --- 「changeUses」の場合
                // ダイアログを表示する
                const modalId = CO_BUY_NOW_MODAL.CHANGE_USES;
                locateModal(modalId);
                const confirmResult = await confirm(modalId);
                if (confirmResult === CONFIRMATION.RESULT.OK) {
                    // --- OKボタンが押された場合
                    actionResult = CO_BUY_NOW_ACTION_RESULT.CHANGE_USES_OK;
                } else {
                    // --- OKボタンが押されなかった場合
                    actionResult = CO_BUY_NOW_ACTION_RESULT.CHANGE_USES_NG;
                }
            } else {
                // --- どの条件にも合致しなかった場合
                actionResult = CO_BUY_NOW_ACTION_RESULT.UNKNOWN;
            }
        }
    } catch (err) {
        response = null;
        actionResult = CO_BUY_NOW_ACTION_RESULT.UNKNOWN;
    } finally {
        $.spinner().stop();
    }
    return {
        response,
        actionResult,
    };
}

/**
 * 「すぐ購入」のAjaxサービスを呼び出し、その結果に応じた処理を実行する。
 *
 * - 結果が「CSRFトークンチェックエラー」の場合
 *     - CSRFエラー画面に遷移する。
 * - 結果が「未ログイン」の場合
 *     - 「ログイン」画面に遷移する。
 * - 結果が「正常」の場合
 *     - 「お支払い方法」または「ご注文内容確認」画面に遷移する。
 * - 結果が「エラー」の場合
 *     - モーダルダイアログ「co/coBuyNow/coBuyNowErrorModal.isml」の内容を表示する。
 *     - モーダルダイアログを閉じると、「originUrl」の画面に遷移する。
 * - 結果が「商品が購入不可である」の場合
 *     - モーダルダイアログ「co/coBuyNow/coBuyNowCanNotBuyModal.isml」を表示する。
 *     - モーダルダイアログを閉じると、「originUrl」の画面に遷移する。
 * - 結果が「用途変更」の場合
 *     - モーダルダイアログ「co/coBuyNow/coBuyNowChangeUsesModal.isml」を表示する。
 *          - 用途変更を許可した場合
 *              - 「forceChangeUses」を「true」にして再度「すぐ購入」のAjaxサービスを呼び出す。
 *          - 用途変更を許可しなかった場合
 *              - ここで終了する。
 *
 * @param {object} buyNowObj - 「すぐ購入」のAjaxサービスに送信するデータを含むオブジェクト。フォーム定義「coBuyNow.xml」に対応する。
 * - `originUrl`: <「すぐ購入」アクション発生元の画面のURL。「/」始まりとする。>
 * - `productId`: <カートに追加する商品のID>
 * - `quantity`: <カートに追加する商品の数量>
 * - `forceChangeUses`: <用途を強制的に変更するか否か>
 * (例) productIdおよびquantityが送信された場合
 * ```js
 * {
 *     originUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Product-Show?pid=12567',
 *     productId: '12567',
 *     quantity: 2,
 *     forceChangeUses: false,
 * }
 * (例) queryString, productId, quantityが送信されなかった場合(値はnullとなる)
 * ```js
 * {
 *     originUrl: '/on/demandware.store/Sites-taneya-Site/ja_JP/Product-Show?pid=12567',
 *     productId: null,
 *     quantity: null,
 *     forceChangeUses: false,
 * }
 * ```
 * @returns {void}
 */
async function execBuyNow(buyNowObj) {
    // CSRFトークンを取得する
    const csrfObject = getCsrfObject();
    const csrfTokenName = csrfObject.tokenName;
    const csrfTokenValue = csrfObject.token;

    // 「すぐ購入」用のFormDataを作成する
    const coBuyNowFormData = new FormData();
    coBuyNowFormData.append('originUrl', buyNowObj.originUrl || '');
    coBuyNowFormData.append('productId', buyNowObj.productId || '');
    coBuyNowFormData.append('quantity', buyNowObj.quantity || '');
    coBuyNowFormData.append(
        'forceChangeUses',
        buyNowObj.forceChangeUses === true ? 'true' : 'false'
    );
    coBuyNowFormData.append(csrfTokenName, csrfTokenValue);

    // 「すぐ購入」フォームの送信先のURLを取得する
    const $coBuyNowPartsInfo = $('#coBuyNowPartsInfo');
    const coBuyNowActionUrl = $coBuyNowPartsInfo.data('url');
    // 「すぐ購入」サービスを呼び出す
    const {response, actionResult} = await doCoBuyNowServiceAction(
        coBuyNowActionUrl,
        coBuyNowFormData
    );

    if (actionResult === CO_BUY_NOW_ACTION_RESULT.CHANGE_USES_OK) {
        // --- 「用途を「ご自宅用」に変更することが承認された場合
        // forceChangeUsesをtrueに設定したbuyNowObを新たに作成する
        const reBuyNowObj = {};
        Object.assign(reBuyNowObj, buyNowObj);
        reBuyNowObj.forceChangeUses = true;
        // 本関数を再度呼び出す
        await execBuyNow(reBuyNowObj);
        return;
    }
    let redirectUrl;
    if (
        actionResult === CO_BUY_NOW_ACTION_RESULT.CSRF_FAIL ||
        actionResult === CO_BUY_NOW_ACTION_RESULT.NOT_LOGGED_IN ||
        actionResult === CO_BUY_NOW_ACTION_RESULT.OK
    ) {
        // --- 未ログインまたは「すぐ購入」が可能である場合
        // redirectUrlのURLに移動する
        redirectUrl = response.redirectUrl;
    } else {
        // --- それ以外の場合
        const $coBuyNowInfo = $('#coBuyNowInfo');
        const topPageUrl = $coBuyNowInfo.data('co-top-page-url');
        // 元の画面が存在する場合はそこに、存在しない場合はトップに移動する
        redirectUrl = response.originUrl || topPageUrl || null;
    }
    if (redirectUrl) {
        // 遷移が完了するまで、スピナーは表示したままとする
        $.spinner().start();
        window.location.href = redirectUrl;
    }
}

module.exports = {
    execBuyNow,
};
