'use strict';

/**
 * セキュリティーに関する処理に必要な情報を格納するためのタグを取得して返す。
 * 「components/security.isml」がインクルードされていることを前提とする。
 *
 * @returns {JQuery<Element>} idが"secInfo"のspanタグを表すjQueryオブジェクト
 */
function getSecInfo() {
    const $secInfo = $('#secInfo');
    return $secInfo;
}

/**
 * CSRFトークンを含むオブジェクトを返す。
 *
 * @returns {object} CSRFトークンを含むオブジェクト
 * - tokenName: 'CSRFトークン名'
 * - token: 'CSRFトークン値'
 * 例:
 * ```js
 * {
 *     tokenName: 'csrf_token',
 *     token: 'lSb3zUKYcM2Q3TXQiqrtvQvEENcg7eJ5lgqUMw-2k5ZiQLpFENIUsd4LUAWEKF7BboUkovZ8F-1tBBgZNqYG6ulLL7Epkta2GRc_9IpCvRaS_soWu0TRWMm83-hkOSjq5_wdtQ5zifwS_qaYRtE9A9YfmnWmmz_7gx7U21PWEX4TV3Sme44='
 * }
 * ```
 */
function getCsrfObject() {
    const $secInfo = getSecInfo();
    const csrf = $secInfo.data('csrf');
    return csrf;
}

module.exports = {
    getCsrfObject,
};
