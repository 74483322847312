'use strict';

/*
 * 二重送信防止用の処理を提供する。
 *
 * ## 利用例
 *
 * const clicklock = require('app_common/components/clicklock');
 * ...
 *
 * function submit() {
 *     // クリックイベントをロックする
 *     if (!clicklock.lock()) {
 *         // ロックできなかった場合は何もしない
 *         return;
 *     }
 *     try {
 *         ... 送信処理
 *     } finally {
 *         // クリックイベントをアンロックする
 *         clicklock.unlock();
 *     }
 * }
 */

/** @type {object} windowオブジェクト内に状態を保持するためのオブジェクト */
window.sswd = {
    /** @type {object} clicklock向けのオブジェクト */
    clicklock: {
        /** @type {boolean} クリックイベントがロックされているか否か */
        locked: false,
    },
};

/**
 * クリックイベントをロックする。
 *
 * @returns {boolean} ロックに成功した場合はtrue、失敗した場合(すでにロックされている場合)はfalse。
 */
function lock() {
    if (window.sswd.clicklock.locked) {
        return false;
    }
    window.sswd.clicklock.locked = true;
    return true;
}

/**
 * クリックイベントをアンロックする。
 *
 * @returns {boolean} 必ずtrueを返す。
 */
function unlock() {
    window.sswd.clicklock.locked = false;
    return true;
}

module.exports = {
    lock,
    unlock,
};
