'use strict';

const focusHelper = require('base/components/focus');
const age = require('../common/age');

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    let trackingConsentData = $('.tracking-consent');
    if (!trackingConsentData.data('caonline')) {
        return;
    }

    let urlContent = trackingConsentData.data('url');
    let urlAccept = trackingConsentData.data('accept');
    let urlReject = trackingConsentData.data('reject');
    let textYes = trackingConsentData.data('accepttext');
    let textNo = trackingConsentData.data('rejecttext');
    let textHeader = trackingConsentData.data('heading');
    let textClose = trackingConsentData.data('close');
    let tokenName = trackingConsentData.data('tokenname');
    let token = trackingConsentData.data('token');

    let htmlString =
        '<!-- Modal -->' +
        '<div aria-labelledby="cookie-consent" class="modal" id="consent-tracking" role="dialog" tabindex="-1">' +
        '<div class="modal-dialog">' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '<h4 class="modal-title">' +
        textHeader +
        '</h4>' +
        '<button type="button" class="close" data-dismiss="modal" aria-label="Close" data-url="' +
        urlReject +
        '"><span aria-hidden="true">' +
        textClose +
        '</span></button>' +
        '</div>' +
        '<div id="consent-tracking-body" class="modal-body"></div>' +
        '<div class="modal-footer">' +
        '<div class="row flex-row-reverse">' +
        '<div class="col-6">' +
        '<button class="affirm btn btn-block btn-lg btn-primary" data-url="' +
        urlAccept +
        '" data-dismiss="modal">' +
        textYes +
        '</button>' +
        '</div>' +
        '<div class="col-6">' +
        '<button class="decline btn btn-save btn-block btn-lg border-dark btn-light" data-url="' +
        urlReject +
        '" data-dismiss="modal">' +
        textNo +
        '</button>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $.spinner().start();
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('#consent-tracking-body').html(response);
            $('#consent-tracking').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $('#consent-tracking').remove();
        },
    });

    $('#consent-tracking button').click(function (e) {
        e.preventDefault();
        let url = $(this).data('url');
        let data = {};
        data[tokenName] = token;
        $.ajax({
            url: url,
            type: 'post',
            data: data,
            dataType: 'json',
            success: function (response) {
                // Only hide modal if the operation is successful - don't want to give a false impression
                if (response.success) {
                    $('#consent-tracking').remove();
                    age.showConfirmAgeModal();
                    $.spinner().stop();
                }
            },
            error: function (err) {
                // Expected error response is for CSRF failure, which will include a redirect to CSRF-Fail
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            },
        });
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }

    $('body').on('shown.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'true');
        $('#consent-tracking .affirm').focus();
    });

    $('body').on('hidden.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'false');
        $.spinner().stop();
    });

    $('body').on('keydown', '#consent-tracking', function (e) {
        let focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: '.affirm',
            lastElementSelector: '.decline',
            nextToLastElementSelector: '.affirm',
        };
        focusHelper.setTabNextFocus(focusParams);
    });
};
